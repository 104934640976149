import React from 'react';

import { 
    DragDropContext, 
    Droppable, 
    Draggable, 
    DraggingStyle, 
    NotDraggingStyle, 
    DropResult,
} from 'react-beautiful-dnd';
import { includes } from 'ramda';
import clone from 'lodash/clone';
import isNil from 'lodash/isNil';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import COLORS from 'constants/colors';
import { isDefined } from 'utils/helpers';
import { getInfo } from 'utils/imgix';
import { getBlobDataFromImageURL } from 'utils/images2';
import { getDimensions } from 'components/fileuploader/ImageUploader2';
import ImgixBuilder from './ImgixBuilder';
import EditImageDialog from './EditImageDialog';
import { 
    AutogeneratedStyle, 
    HiddenAutogeneratedStyle, 
    MainImageStyle, 
    StyledButton, 
} from './ImageSorter.style';
import { extractURLParam } from 'utils/urlUtils';

export interface IImage {
    pictureUrl?: string;
    isExisting?: boolean;
    imgSrc?: string;
    id: string;
    width?: number;
    height?: number;
    size?: number;
    sizeMB?: number;
    // ppi?: number;
    isArtwork?: boolean;
}

const reorder = (list: IImage[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const isAutoGenerated = (image: IImage) => {
    const { pictureUrl } = image;
    return pictureUrl ? pictureUrl.includes('AIGENERATED') : false;
}

const isArtwork = (image: IImage) => {
    return image.isArtwork || false;
    // console.log('!!!images - isArtwork', image, isNil(image.isArtwork));
    // if (!isNil(image.isArtwork)) return image.isArtwork;
    // const { pictureUrl } = image;

    // return pictureUrl ? !!extractURLParam("ARTWORK", pictureUrl) : false
};

const isOldImage = (image: IImage) => {
    const { pictureUrl } = image;
    if (pictureUrl?.includes('https://foodlab2b.imgix.net/')) return true;
    return false;
};

const getImageStyle = (itemIndex: number): React.CSSProperties => ({
    display: 'inline-flex',
    borderRadius: 2,
    //width: 120,
    height: 120,
    boxSizing: 'border-box',
    cursor: 'pointer',
});

const grid = 4;

const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    index: number,
    isMarkedForRemoval: boolean,
): React.CSSProperties => ({
    maxHeight: 400,
    userSelect: 'none',
    padding: isMarkedForRemoval ? grid * 2 - 4 : grid * 2,
    margin: `0 ${grid}px 0 0`,
    borderRadius: 2,
    display: 'flex',
    border: isMarkedForRemoval ? `4px solid ${COLORS.mainRed}` : 'none',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: isDragging
        ? 'rgba(173, 213, 255, 0.98)'
        : index !== 0
        ? "white"
        : "white",
    ...draggableStyle,
});

const downloadImage = async (src?: string) => {
    if (src) {
        const originalImageUrl = src.split('?')[0];
        const blob = await getBlobDataFromImageURL(src);
        let fileName = originalImageUrl.substr(originalImageUrl.lastIndexOf('/') + 1);

        const aLink = document.createElement('a');
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.target = '_blank';
        aLink.className = 'J_notOpenInContainer';
        document.body.appendChild(aLink);
        aLink.click();
        URL.revokeObjectURL(aLink.href);
    }
};

const imageIsToBeRemoved = (image?: IImage, imagesToRemove?: IImage[]) => {
    if (!image || !imagesToRemove) return false;
    return includes(image, imagesToRemove);
}

const getImageMetadata = async (image: IImage) => {
    // pictureUrl
    // id
    // imgSrc
    // isExisting
    // isNew
    let width = 0;
    let height = 0;
    let size = 0;
    let sizeMB = 0;
    // let ppi = 0; DPI
    try {
        if (image.isExisting) {
            const info = await getInfo(image.pictureUrl);
            // console.log('!!!info', info);
            if (info?.PixelWidth) {
                width = info.PixelWidth;
            }
            if (info?.PixelHeight) {
                height = info.PixelHeight;
            }
            if (info?.['Content-Length']) {
              size = Number(info?.['Content-Length'] || '0');
            }
            // const dpi = getDpi(info); DPI
            // if (dpi) { DPI
            //     ppi = dpi; DPI
            // }
            // if (info?.DPIWidth) {
            //     ppi = info.DPIWidth;
            // }
        } else {
            const result = await getDimensions(image?.imgSrc);
            width = result?.width;
            height = result?.height;
            size = result?.size || 0;
            if (image?.imgSrc) {
                const response = await fetch(image.imgSrc);
                const data = await response.blob();
                const type = response.headers.get('content-type') || 'image/png'; // TODO
                const file = new File([data], 'notused.png', {
                    type,
                });
                // ppi = await getFileDpi(file); DPI
            }        }
    } catch (error: any) {
        console.error('Failed to get metadata: ', error.message);
    }
    if (size > 0) {
      sizeMB = Math.ceil(size / (1024 * 1024) * 10) / 10;
    }
    const res: IImage = {...image, width, height, size, sizeMB};
    if (isNil(image.isArtwork) && res.pictureUrl && res.pictureUrl.trim() !== '') {
        res.isArtwork = !!extractURLParam("ARTWORK", res.pictureUrl);
    }  
    // const res = {...image, width, height, ppi};  DPI
    // console.log('!!!res', res);
    return res;
};

const getListStyle = (isDraggingOver: boolean) => ({
    marginTop: 20,
    marginBottom: 20,
    background: isDraggingOver ? 'rgba(173, 213, 255, 0.5)' : 'lightgrey',
    borderRadius: 3,
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

interface IImageSorterProps {
    images?: IImage[];
    imagesToBeRemoved?: IImage[];
    showMetadata?: boolean;
    isDownload?: boolean;
    isEdit?: boolean;
    shouldShowArtwork?: boolean;
    onOrderChanged: (images: IImage[]) => void;
    onDeleteOrKeepImagePressed: (image: IImage) => void;
    containerStyle?: React.CSSProperties;
    disableEdit?: boolean;
    isMagazine?: boolean;
}

const ImageSorter = ({
    images,
    imagesToBeRemoved,
    onDeleteOrKeepImagePressed,
    onOrderChanged,
    containerStyle,
    showMetadata,
    isDownload,
    isEdit,
    shouldShowArtwork,
    disableEdit,
    isMagazine,
}: IImageSorterProps) => {
    // console.log('!!!images', images);
    const [newImages, setNewImages] = React.useState<IImage[]>([]);
    const [open, setOpen] = React.useState(false);
    const [url, setUrl] = React.useState<string|undefined>(undefined);
    React.useEffect(() => {
        if (images && images.length > 0) {
            console.log('!!!images', images);
            Promise.all(images.map(getImageMetadata)).then(setNewImages);
        }
    }, [images]);
    // console.log('!!!new', newImages);
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            newImages,
            result.source.index,
            result.destination.index
        );

        onOrderChanged(items);
    };

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [currentImage, setCurrentImage] = React.useState<IImage|undefined>(undefined);

    const onArtWorkToggle = (img: IImage) => {
        const imageIndex = newImages.findIndex(image => img.id === image.id);

        if(imageIndex < 0 || !img.pictureUrl) return;

        const updatedCurrentImage = {...img};
        // const url = new URL(currentImage.pictureUrl);

        if(img.isArtwork) {
            updatedCurrentImage.isArtwork = false;
            // url.searchParams.delete("ARTWORK");
        } else {
            updatedCurrentImage.isArtwork = true;
            // url.searchParams.set("ARTWORK", "1");
        }

        // updatedCurrentImage.pictureUrl = url.href;
        const updatesImages = [...newImages];
        updatesImages[imageIndex] = updatedCurrentImage;

        onOrderChanged(updatesImages);
    };

    const addEditImage = (value?: string) => {
        if (value && currentImage && currentImage !== undefined && currentImage !== null) {            
            const values = newImages ? clone(newImages) : [];
            const imgix = ImgixBuilder.parse(value);
            const newImage = {
                ...currentImage, 
                imgSrc: imgix.getThumb(), 
                pictureUrl: value,
                id: value,
            };
            values.splice(currentIndex + 1, 0, newImage);
            onOrderChanged(values);
        }
    };

    const formControlStyles = {
        margin: 0,
        justifyContent: "center",
    };
    
    return isDefined(newImages) && newImages.length > 0 ? (
        <>
        <DragDropContext onDragEnd={onDragEnd}>
             {/* TODO style={containerStyle}> */}
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {newImages.map((item, index) => {
                            const onEditImage = () => {
                                setCurrentIndex(index);
                                setCurrentImage(item);
                                setUrl(item.pictureUrl);
                                setOpen(true);
                            };

                            return (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            index,
                                            imageIsToBeRemoved(
                                                item,
                                                imagesToBeRemoved
                                            )
                                        )}
                                    >
                                      <div style={{marginLeft: "auto", marginRight: "auto"}}>
                                        <img
                                            src={item.imgSrc}
                                            style={getImageStyle(index)}
                                            alt="Not available"
                                            onClick={onEditImage}
                                        />
                                      </div>
                                        {index === 0 && (
                                            <MainImageStyle>Main image</MainImageStyle>
                                        )}
                                        {isAutoGenerated(item) && (
                                          <AutogeneratedStyle>Auto generated</AutogeneratedStyle>
                                        )}
                                        {!isAutoGenerated(item) && showMetadata && (
                                            <HiddenAutogeneratedStyle>Auto generated</HiddenAutogeneratedStyle>
                                        )}
                                        {shouldShowArtwork && (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isArtwork(item)}
                                                        onChange={() => onArtWorkToggle(item)}
                                                        name="artwork"
                                                        color="primary"
                                                        size="small"
                                                        style={{ paddingRight: "5px", paddingLeft: 0 }}
                                                    />
                                                }
                                                labelPlacement="end"
                                                label={"Artwork"}
                                                style={formControlStyles}
                                            />
                                        )}
                                        {showMetadata && (
                                            <div style={{
                                                margin: 2,
                                            }}>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: 12,
                                                    margin: 0,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: '700',
                                                        margin: 0,
                                                        color: (item?.sizeMB || 0) > 25 ? 'red' : 'black'
                                                    }}
                                                >
                                                  {item?.sizeMB || 0} mb
                                                </p>
                                                {item?.width || '??'}x{item?.height || '??'}
                                            </p>
                                            {/* <p DPI
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: 12,
                                                    margin: 0,
                                                }}
                                            >
                                                {item?.ppi || '??'} ppi
                                            </p> */} 
                                            </div>
                                        )}
                                        {/* <Tooltip title={isOldImage(item) ? "You can't remove this image. We are working on fixing it" : "" }> */}
                                            <div>
                                                <StyledButton
                                                    disabled={isMagazine}
                                                    // disabled={isOldImage(item) || isMagazine}
                                                    onClick={() =>
                                                        onDeleteOrKeepImagePressed(item)
                                                    }
                                                >
                                                    {imageIsToBeRemoved(
                                                        item,
                                                        imagesToBeRemoved
                                                    )
                                                        ? 'Keep'
                                                        : 'Remove'}
                                                </StyledButton>
                                            </div>
                                        {/* </Tooltip> */}
                                        {isDownload && (
                                            // <Tooltip title={isOldImage(item) ? "You can't download this image. We are working on fixing it" : "" }>
                                                <div>
                                                    <StyledButton
                                                        // disabled={isOldImage(item)}
                                                        onClick={() => {
                                                            downloadImage(item.pictureUrl)
                                                        }}
                                                    >
                                                        Download
                                                    </StyledButton>
                                                </div>
                                            // </Tooltip>
                                        )}
                                        {isEdit && (
                                            // <Tooltip title={isOldImage(item) ? "You can't edit this image. We are working on fixing it" : "" }>
                                                <div>
                                                    <StyledButton
                                                        disabled={disableEdit || isMagazine}
                                                        // disabled={disableEdit || isOldImage(item) || isMagazine}
                                                        onClick={onEditImage}
                                                    >
                                                        Edit
                                                    </StyledButton>
                                                </div>
                                            // </Tooltip>
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        <EditImageDialog
            open={open} 
            url={url} 
            onClose={() => setOpen(false)} 
            onSave={(img) => {
                addEditImage(img);
                setOpen(false);
            }}
        />
        </>
    ) : (
        <></>
    );
};

export default ImageSorter;
