import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from 'components/button';
import useDataContext from '../hooks/useDataContext';

export interface ImportCompletedModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ImportCompletedModal: FC<ImportCompletedModalProps> = ({ open, setOpen }) => {
  const history = useHistory();
  const { onReset } = useDataContext();

  const handleRestart = () => {
    setOpen?.(false);
    onReset();
  };

  return (
    <Dialog open={open}>
      <DialogContent>Dina produkter har importerats!</DialogContent>
      <DialogActions>
        <Box display="flex" gridGap={16} px={2} py={1}>
          <Button size="small" variant="outlined" onClick={handleRestart}>
            Importera fler produkter
          </Button>
          <Button size="small" variant="contained" onClick={() => history.push('/products')}>
            Se mina produkter
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCompletedModal;
