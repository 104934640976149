import isNil from "lodash/isNil";

import IProduct from "../../../models/product";
import { IFoodlaCategoryOption } from "models/category";
import { requiredNutritionalFields } from "./constants";
import { isNonFoodProduct } from "utils/products";

export type TAlertCheckOption = {
  categories: Omit<IFoodlaCategoryOption, 'children'>[]
}

export const isEmptyEAN = (product?: IProduct) => {
  if (product) {
    if (!product.EAN || product.EAN.trim() === '') {
      return true;
    }
  }
  return false;
};

export const is2197EAN = (product?: IProduct) => {
  // console.log('!!!2197', product);
  if (product?.producerIsStore) {
    if (product.EAN && product.EAN.trim().startsWith('2197')) {
      return true;
    }
  }
  return false;
};

const fieldIsBlank = (field?: any) => {
  if (isNil(field)) return true;
  if (typeof field === 'string' && field.trim() === '') return true;
  return false;
};

export const isEmptyNutritional = (product?: IProduct) => {
  if (product && !isNonFoodProduct(product) && !product?.producerIsStore) {
    if (
      requiredNutritionalFields.some((field) => product && fieldIsBlank(product[field]))
      || !product.nutritional_unit
    ) {
      return true;
    }
  }
  return false;
};

export const isWrongCoopStoreEAN = (product?: IProduct) => {
  if (product?.producerIsStore && product?.producerParentOrg === 'coop' && product?.producerOrg !== 'coop/coopvarmland') {
    // console.log('product', product);
    if (product.EAN) {
      if (
        !(
          product.EAN.trim().startsWith('20001')
          || product.EAN.trim().startsWith('20002')
          || product.EAN.trim().startsWith('20003')
          || product.EAN.trim().startsWith('20004')
          || product.EAN.trim().startsWith('20005')
          || product.EAN.trim().startsWith('20006')
          || product.EAN.trim().startsWith('20007')
          || product.EAN.trim().startsWith('20008')
          || product.EAN.trim().startsWith('200090000000')
          || product.EAN.trim().startsWith('20975')
          || product.EAN.trim().startsWith('20976')
          || product.EAN.trim().startsWith('20977')
          || product.EAN.trim().startsWith('20978')
          || product.EAN.trim().startsWith('20979')
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

export const isWrongEANLength = (product?: IProduct) => {
  if (product) {
    if (
      product.EAN && (
        (product.EAN.length !== 8 && product.EAN.length !== 13)
        || (product.EAN.trim().length !== 8 && product.EAN.trim().length !== 13)
      )
    ) {
      return true;
    }
  }
  return false;
};

export const isEmptyTitle = (product?: IProduct) => {
  if (product) {
    if (!product.title || product.title.trim() === '') {
      return true;
    }
  }
  return false;
};

export const isEmptyImage = (product?: IProduct) => {
  if (product) {
    if (!product.image_src || product.image_src.trim() === '') {
      return true;
    }
  }
  return false;
};

export const isEmptyCategory = (product?: IProduct) => {
  if (product) {
    if (
      !product.foodlaCategory
      || !product.foodlaCategory.id
      || product.foodlaCategory.id.trim() === ''
      || !product.foodlaCategory.name
      || product.foodlaCategory.name.trim() === ''
    ) {
      return true;
    }
  }
  return false;
};

export const isWrongCategoryMapping = (product?: IProduct) => {
  if (product) {
    if (product.foodlaCategory && product.foodlaCategory.id) {
      if (!product.newCategory) {
        return true;
      }
    }
  } 
  return false;
};

// TODO: isWrongCoopCategory
// TODO: isWrongApoteaCategory
// TODO: isWrongIcaCategory

export const isEmptyStore = (product?: IProduct) => {
  if (product) {
    const { producer } = product;
    if (producer && producer.isStore) {
      if (!producer.storeId || producer.storeId.trim() === '') {
        return true;
      }
    }
  }
  return false;
};

const getControlDigit = (ean?: string) => {
  if (ean) {
    const EAN = ean.trim();
    if (EAN.length === 13 || EAN.length === 12) {
      const total = (parseInt(EAN[0]) + parseInt(EAN[2]) + parseInt(EAN[4]) + parseInt(EAN[6]) + parseInt(EAN[8]) + parseInt(EAN[10]))
        + 3 * (parseInt(EAN[1]) + parseInt(EAN[3]) + parseInt(EAN[5]) + parseInt(EAN[7]) + parseInt(EAN[9]) + parseInt(EAN[11]));
      let control = 10 - (total % 10);
      // console.log('!total', total, control);
      if (control === 10) control = 0;
      return control;
    }
  }
  return undefined;
};

const checkEAN = (ean?: string) => {
  if (ean) {
    const EAN = ean.trim();
    if (EAN.length === 13) {
      const control = getControlDigit(ean);
      return control === parseInt(EAN[12]);
    }
  }
  return true;
};

export const isWrongControlDigit = (product?: IProduct) => {
  if (product) {
    if (
      product.EAN
      && product.EAN.trim().length === 13
      && !product.EAN.trim().startsWith('23') && !product.EAN.trim().startsWith('2000') && !product.EAN.trim().startsWith('2097')
      && !checkEAN(product.EAN)
    ) {
      return true;
    }
  }
  return false;
};

export const isWrongEANSymbols = (product?: IProduct) => {
  if (product) {
    if (product.EAN && product.EAN.trim() !== '') {
      const result = /^\d+$/.test(product.EAN);
      return !result;
    }
  }
  return false;
};

export const isWrongUnit = (product?: IProduct) => {
  if (product) {
    if (product.EAN) {
      if (
        (
          product.EAN.trim().startsWith('23')
          || product.EAN.trim().startsWith('2000')
          || product.EAN.trim().startsWith('2097')
        )
        && product.descriptive_size_amount
        && product.descriptive_size_unit !== 'GRAM_APPROXIMATE'
      ) {
        return true;
      }
    }
  }
  return false;
};

// export const isItemCategoriesOnlineEmpty = (product?: IProduct) => {
//   if (product) {
//     const p: any = product;
//     // TODO
//     if (!p.ItemCategoriesOnline || p.ItemCategoriesOnline.trim() === '') {
//       return true;
//     }
//     if (p?.productNotes) {
//     }
//   }
//   return false;
// };

export const isNoTemperature = (product?: IProduct) => {
  if (product && !isNonFoodProduct(product)) {
    if (isNil(product.max_temperature) || isNil(product.min_temperature)) {
      return true;
    }
  }
  return false;
};

export const isGrossWeightEmpty = (product?: IProduct) => {
  if (product) {
    if (!product.gross_weight_num) {
      return true;
    }
  }
  return false;
};

export const isTitleContainsEKO = (product?: IProduct) => {
  if (product) {
    if (product.title) {
      return product.title.includes('EKO');
    }
  }
  return false;
};

export const isNoDegreeCharacter = (product?: IProduct) => {
  if (product) {
    if (!isNonFoodProduct(product)) {
      const temp = product.trade_item_temperature_information;
      if (temp && /\d/.test(temp)) {
        return !/\d\s?[°˚ºCK℃]/.test(temp);
      }
    }
  }
  return false;
};

export const isEmptyCountryOfManufacturing = (product?: IProduct) => {
  if (product) {
    if (isNil(product.country_of_manufacturing)) {
      return true;
    }
  }
  return false;
};

export const isEAN2Empty = (product?: IProduct) => {
  if (product) {
    let ean = product.EAN;
    const isEmpty = !product.EAN2 || product.EAN2.trim() === '';
    if (ean) {
      ean = ean.trim();
      if (ean.length === 13 && (ean.startsWith('23') || ean.startsWith('2097') || ean.startsWith('2000'))) {
        if (ean[8] !== '0' || ean[9] !== '0' || ean[10] !== '0' || ean[11] !== '0') {
          if (isEmpty) {
            return true;
          }
        } else if (ean[8] === '0' && ean[9] === '0' && ean[10] === '0' && ean[11] === '0') {
          const control = getControlDigit(ean);
          if (!isNil(control)) {
            if (control.toString() !== ean[12]) {
              if (isEmpty) {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
};

export const isDescriptiveSizeAmountEmpty = (product?: IProduct) => {
  if (product) {
    if (!isNonFoodProduct(product)) {
      return !product.descriptive_size_amount;
    }
  }
  return false;
};

// export const isPackageSizeEmpty = (product?: IProduct) => {
//   if (product) {
//     if (isNonFoodProduct(product)) {
//       return !product.package_size;
//     }
//   }
//   return false;
// };

export const isGrossDimensionsEmpty = (product?: IProduct) => {
  if (product) {
    if (!product.gross_height && !product.gross_width && !product.gross_depth) {
      return true;
    }
  }
  return false;
};

const getSet = (value: string) => {
  const splitRegex = /(\s+|-)/;
  const set = new Set(
    value
      .toLowerCase()
      .split(splitRegex)
      .map((x) => x.trim())
      .filter((x) => x.length > 2),
  );
  return set;
};

export const isTitleContainsBrand = (product?: IProduct) => {
  if (product && product.title) {
    const titleWords = getSet(product.title);
    if (titleWords.size > 0) {
      let words = '';
      if (isNonFoodProduct(product)) {
        if (product.brand) {
          words = `${words} ${product.brand}`;
        }
      } else if (product.brand_food) {
        words = `${words} ${product.brand_food}`;
      }
      if (product.producer && product.producer.name) {
        words = `${words} ${product.producer.name}`;
      }
      const brandWords = getSet(words);
      // console.log(brandWords);
      if (brandWords.size > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const value of Array.from(brandWords)) {
          if (titleWords.has(value)) {
            // console.log(brandWords);
            // console.log(titleWords);
            return true;
          }
        }
      }
    }
  }
  return false;
};
