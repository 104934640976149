import { FC, useContext } from 'react';

import IProduct from 'models/product';
import { Box } from '@material-ui/core';
import { KeycloakContext } from 'components/Secured';

interface DraftProductAlertProps {
  product?: IProduct;
}

const DraftProductAlert: FC<DraftProductAlertProps> = ({ product }) => {
  const keycloakCtx = useContext(KeycloakContext);

  const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');

  let message = '';
  if (isSuperuser) {
    message =
      'Product is saved as draft. Disable the "save as draft" toggle button at the bottom of the page in order to update product.';
  }
  if (isProducer || isStore) {
    message =
      'Product is saved as draft. Disable the "save as draft" toggle button at the bottom of the page and update product to submit for review.';
  }

  if (!product?.isDraft || !message) {
    return null;
  }

  return (
    <Box bgcolor="#e4e4e4" px={1.5} py={1} mb={2} fontStyle="italic" borderRadius={4}>
      {message}
    </Box>
  );
};

export default DraftProductAlert;
