import React, { useEffect, useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import { Query } from 'react-apollo';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AsyncDataHandler from '../../components/AsyncDataHandler';
import ProductCardList from '../../components/product/ProductCardList/ProductCardList';
import { KeycloakContext } from '../../components/Secured';

import { ProductTabs } from '../../components/product';
import {
    QUERY_PRODUCT_SUMMARY,
    QUERY_PRODUCER_PRODUCTS,
    ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY,
} from '../../graphql/queries';
import {
    extractURLParam,
    addOrChangeQueryParam,
    pushToWindowHistory,
} from '../../utils/urlUtils';
import { isValidTabParam } from '../../utils/products';
import { IS_FOOD_LOCAL_STORAGE_KEY, PRODUCT_TABS } from '../../constants/domain';
import { useQuery } from '@apollo/react-hooks';
import { path } from 'ramda';
import { isDefined, useOrElse } from '../../utils/helpers';

const styles = theme => ({
    breadcrumb: {
        marginBottom: theme.spacing(2),
    },
    progress: {
        margin: theme.spacing(10),
    },
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    toolbarButton: {
        marginLeft: theme.spacing(2),
    },
});

const ProductsBreadCrumbs = withStyles(styles)(
    ({ classes, producerId, name, ...rest }) => {
        return (
            <Breadcrumbs
                className={classes.breadcrumb}
                separator="›"
                aria-label="Breadcrumb"
            >
                <Link color="inherit" component={RouterLink} to="/">
                    Home
                </Link>
                <Link color="inherit" component={RouterLink} to="/producers">
                    Producers
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/producers/${producerId}/show`}
                >
                    {name}
                </Link>
                <Typography color="textPrimary">Products</Typography>
            </Breadcrumbs>
        );
    }
);

const ProductTabsUpdates2 = ({ category, onTabChange, data, loading, error }) => {
    return (
      <>
        {loading && <></>}
        {!loading && error && <p>Could not load summary data...</p>}
        {isDefined(data) && <ProductTabs category={category} onTabChange={onTabChange} data={data} />}
      </>
    );
};

const ProductTabsUpdates = ({ category, onTabChange, producerId }) => {
    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    let isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
    if (isFood === 'false') {
        isFood = false;
    } else {
        isFood = true;
    }
    return (
        <Query query={QUERY_PRODUCT_SUMMARY} variables={{ id: producerId, isFood, onlyApproved: isStore }}>
            {({ data, ...loadingData }) => {
                return (
                    <AsyncDataHandler
                        {...loadingData}
                        data={data?.producer?.productsSummary}
                    >
                        <ProductTabs
                            category={category}
                            onTabChange={onTabChange}
                            data={data?.producer}
                        />
                    </AsyncDataHandler>
                );
            }}
        </Query>
    );
};

const ProductsPage = ({ match }) => {
    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const isAdmin = keycloakCtx.keycloak.hasResourceRole('admin');
    // Remove listener when unmounting to avoid memory leakage.
    useEffect(() => {
        return function cleanUp() {
            window.onpopstate = () => {};
        };
    });

    const { data: notificationsData } = useQuery(ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY, {
      fetchPolicy: 'cache-and-network',
    })

    // Check URL for search param with information about selected tab.
    const tabParam = extractURLParam('productTab', window.location.href);
    const category = useOrElse(tabParam, PRODUCT_TABS.PENDING);

    const [state, setState] = React.useState({
        category,
    });

    // Handle productId and producerId params
    const { id, producerId: pId } = match.params;
    let producerId = id;
    let selectedProductId;
    if (pId) {
        // ProducerId is available
        producerId = pId;
        // Then, if id exists in params, this is the productId
        selectedProductId = id;
    }

    // When selecting new tab, update state and add proper param for navigation purposes.
    function onTabChange(name) {
        const newURL = addOrChangeQueryParam(
            'productTab',
            name,
            window.location
        );
        pushToWindowHistory(newURL, window, 'ProductTab');

        setState({ category: name });
    }

    // Listen on popstate events (back or foward browser event). Update tabstate with correct tab value.
    // Cleaned up in useEffect hook when unmounting.
    window.onpopstate = () => {
        const tabParam = extractURLParam('productTab', window.location.href);

        if (isValidTabParam(tabParam)) setState({ category: tabParam });
        else if (!tabParam) setState({ category: PRODUCT_TABS.PENDING });
    };

    const notificationResponse = path(['productUpdateNotifications'], notificationsData);
    const productNotifications = isDefined(notificationResponse) ? notificationResponse : [];

    let isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
    if (isFood === 'false') {
        isFood = false;
    } else {
        isFood = true;
    }
    return (
        <Query query={QUERY_PRODUCT_SUMMARY} variables={{ id: producerId, isFood, onlyApproved: isStore || isAdmin }}>
            {({ loading, error, data, refetch }) => {
                if (loading) return <p>Loading...</p>;
                if (error) return <p>Error :(</p>;
                if (!data || !data.producer) return <p>No products</p>;

                let breadcrumb = (
                    <ProductsBreadCrumbs
                        producerId={producerId}
                        name={data.producer.name}
                    />
                );

                return (
                    <>
                        {breadcrumb}
                        <Paper>
                            <ProductTabsUpdates2
                                data={data}
                                loading={loading}
                                error={error}
                                category={state.category}
                                onTabChange={onTabChange}
                            />
                            <Divider />
                            <ProductCardList
                                showDescriptiveSize={true}
                                category={state.category}
                                producerId={producerId}
                                currentTab={state.category}
                                productsQuery={QUERY_PRODUCER_PRODUCTS}
                                queryVariables={{
                                    id: producerId,
                                    filter: { tagState: category, isFood },
                                    onlyApproved: isStore || isAdmin,
                                    includeDraft: !isAdmin,
                                }}
                                queryListDataExtractor={data =>
                                    data?.producer?.products?.edges
                                }
                                selectedProductId={selectedProductId}
                                notificationsData={productNotifications}
                                showSearch={true}
                                showSorting={false}
                                showPagination={false}
                                summaryRefetch={refetch}
                            />
                        </Paper>
                    </>
                );
            }}
        </Query>
    );
};

export default ProductsPage;
