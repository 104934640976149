/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Alert, { Color } from '@material-ui/lab/Alert';

import IProduct from "../../../models/product";
import { 
  TAlertCheckOption,
  isDescriptiveSizeAmountEmpty,
  isEAN2Empty,
  isEmptyCountryOfManufacturing,
  isEmptyEAN, 
  is2197EAN,
  isEmptyStore, 
  isEmptyTitle, 
  isGrossDimensionsEmpty, 
  isGrossWeightEmpty, 
  isNoDegreeCharacter, 
  isNoTemperature, 
  // isPackageSizeEmpty, 
  isTitleContainsBrand,
  isTitleContainsEKO,
  isEmptyCategory, 
  isWrongCategoryMapping,
  // isWrongCoopCategory,
  // isWrongApoteaCategory,
  // isWrongIcaCategory,
  isWrongControlDigit,
  isWrongEANLength, 
  isWrongEANSymbols,
  isWrongUnit,
  // isItemCategoriesOnlineEmpty,
  isWrongCoopStoreEAN,
  isEmptyNutritional,
} from './alerts-check';
import Collapse from '@material-ui/core/Collapse';
import isEmpty from 'lodash/isEmpty';
import { STRIPE_BILLING_URL } from 'constants/domain';
import { IFoodlaCategoryOption } from 'models/category';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       '& > * + *': {
//         margin: theme.spacing(2),
//       },
//     },
//   }),
// );

interface IAlert {
  title: string;
  field: string;
  id: string;
  severity?: Color;
  open?: boolean;
}

interface IAlertsProps {
  isNoHasUnpaidInvoices: boolean;
  state: IProduct;
  sortedImages?: any[];
  nonUniq?: boolean;
  categories: IFoodlaCategoryOption[]
}

interface IErrorAlertProps {
  open?: boolean;
  field: string;
  title: string;
  onClick?: () => void;
}

const scrollWithOffset = (elementId: string) => {
  const el = document.getElementById(elementId);
  if (el) {
    el.focus();
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const ErrorAlert = ({ open, field, title, onClick }: IErrorAlertProps) => {
  return (
    <Collapse in={open}>
      <Alert severity={"error"} style={{margin: 3}}>
        <a
          href="#" 
          onClick={(e) => {
            if (onClick) {
              onClick();
            } else {
              e.preventDefault()
              scrollWithOffset(field);
            }
          }}
        >
          {title}
        </a>
      </Alert>
    </Collapse>
  );
};

export const Alerts = ({ isNoHasUnpaidInvoices, state, sortedImages, nonUniq, categories }: IAlertsProps) => {

  const [openIsEmptyCategory, setOpenIsEmptyCategory] = React.useState(false);
  const [openIsWrongCategoryMapping, setOpenIsWrongCategoryMapping] = React.useState(false);

  const [openIsEmptyTitle, setOpenIsEmptyTitle] = React.useState(false);
  const [openIsTitleContainsBrand, setOpenIsTitleContainsBrand] = React.useState(false);
  const [openIsTitleContainsEKO, setOpenIsTitleContainsEKO] = React.useState(false);
  
  const [openIsEmptyImages, setOpenIsEmptyImages] = React.useState(false);
  
  const [openIsEmptyEAN, setOpenIsEmptyEAN] = React.useState(false);
  const [openIs2197EAN, setOpenIs2197EAN] = React.useState(false);
  const [openIsWrongCoopStoreEAN, setOpenIsWrongCoopStoreEAN] = React.useState(false);
  const [openIsWrongEANLength, setOpenIsWrongEANLength] = React.useState(false);
  const [openIsWrongEANSymbols, setOpenIsWrongEANSymbols] = React.useState(false);
  const [openIsWrongControlDigit, setOpenIsWrongControlDigit] = React.useState(false);
  const [openIsEAN2Empty, setOpenIsEAN2Empty] = React.useState(false);
  const [openIsNonUniqEAN, setOpenIsNonUniqEAN] = React.useState(!nonUniq);

  const [openIsNutritionalInfoNotEmpty, setOpenIsNutritionalInfoNotEmpty] = React.useState(false);

  const [openIsGrossWeightEmpty, setOpenIsGrossWeightEmpty] = React.useState(false);
  const [openIsGrossDimensionsEmpty, setOpenIsGrossDimensionsEmpty] = React.useState(false);

  const [openIsEmptyStore, setOpenIsEmptyStore] = React.useState(false);

  const [openIsDescriptiveSizeAmountEmpty, setOpenIsDescriptiveSizeAmountEmpty] = React.useState(false);
  const [openIsWrongUnit, setOpenIsWrongUnit] = React.useState(false);

  const [openIsNoTemperature, setOpenIsNoTemperature] = React.useState(false);
  const [openIsNoDegreeCharacter, setOpenIsNoDegreeCharacter] = React.useState(false);

  const [openIsEmptyCountryOfManufacturing, setOpenIsEmptyCountryOfManufacturing] = React.useState(false);

  const [openIsPackageSizeEmpty, setOpenIsPackageSizeEmpty] = React.useState(false);
  
  const [openIsItemCategoriesOnlineEmpty, setOpenIsItemCategoriesOnlineEmpty] = React.useState(false);
  const [openIsNutritionalEmpty, setOpenIsNutritionalEmpty] = React.useState(false);

  const checkError = (func: (value?: IProduct, options?: TAlertCheckOption) => boolean, setState: (value: boolean) => void) => {
    if (state) {
      setState(func(state, { categories }));
    } else {
      setState(false);
    }
  };

  const checkEmptyImages = () => {
    setOpenIsEmptyImages(isEmpty(sortedImages));
  };

  React.useEffect(() => {
    checkError(isEmptyCategory, setOpenIsEmptyCategory);
    // checkError(isWrongCategoryMapping, setOpenIsWrongCategoryMapping);
    // TODO: isWrongCoopCategory
    // TODO: isWrongApoteaCategory
    // TODO: isWrongIcaCategory
    
    checkError(isEmptyTitle, setOpenIsEmptyTitle);
    checkError(isTitleContainsBrand, setOpenIsTitleContainsBrand);
    checkError(isTitleContainsEKO, setOpenIsTitleContainsEKO);

    checkEmptyImages();
    
    checkError(isEmptyEAN, setOpenIsEmptyEAN);
    checkError(is2197EAN, setOpenIs2197EAN);
    checkError(isWrongCoopStoreEAN, setOpenIsWrongCoopStoreEAN);
    checkError(isWrongEANLength, setOpenIsWrongEANLength);
    checkError(isWrongEANSymbols, setOpenIsWrongEANSymbols);
    checkError(isWrongControlDigit, setOpenIsWrongControlDigit);
    checkError(isEAN2Empty, setOpenIsEAN2Empty);
    setOpenIsNonUniqEAN(!nonUniq);
    
    checkError(isGrossWeightEmpty, setOpenIsGrossWeightEmpty);
    checkError(isGrossDimensionsEmpty, setOpenIsGrossDimensionsEmpty);

    checkError(isEmptyStore, setOpenIsEmptyStore);
  
    // checkError(isDescriptiveSizeAmountEmpty, setOpenIsDescriptiveSizeAmountEmpty);
    checkError(isWrongUnit, setOpenIsWrongUnit);

    // checkError(isNoTemperature, setOpenIsNoTemperature);
    checkError(isNoDegreeCharacter, setOpenIsNoDegreeCharacter);

    // checkError(isEmptyCountryOfManufacturing, setOpenIsEmptyCountryOfManufacturing);
  
    // checkError(isPackageSizeEmpty, setOpenIsPackageSizeEmpty);
    
    // checkError(isItemCategoriesOnlineEmpty, setOpenIsItemCategoriesOnlineEmpty);
    // checkError(isEmptyNutritional, setOpenIsNutritionalEmpty);
  }, [state, sortedImages, nonUniq, categories]);


  return (
    <div>
      <ErrorAlert
        open={!isNoHasUnpaidInvoices}
        field=""
        title="Has unpaid invoices"
        onClick={() => window.open(STRIPE_BILLING_URL, '_blank')}
      />

      <ErrorAlert field="category-tree-select" title="Empty category" open={openIsEmptyCategory} />
      {/* TODO: enable <ErrorAlert field="category-tree-select" title="Wrong category mapping" open={openIsWrongCategoryMapping} /> */}

      <ErrorAlert field="title" title="Empty title" open={openIsEmptyTitle} />
      <ErrorAlert field="title" title="Field “Produktnamn” contains any part from field “Varumärke” and/or field “Namn som visas” (producer name)" open={openIsTitleContainsBrand} />
      <ErrorAlert field="title" title="Field “Produktnamn” contains word “EKO” (all capital letters)" open={openIsTitleContainsEKO} />
      
      <ErrorAlert field="images" title="No images" open={openIsEmptyImages} />

      <ErrorAlert field="EAN" title="EAN is empty" open={openIsEmptyEAN} />
      <ErrorAlert field="EAN" title="Wrong EAN for store products (“2197…”)" open={openIs2197EAN} />
      <ErrorAlert field="EAN" title="Wrong EAN for store products" open={openIsWrongCoopStoreEAN} />
      <ErrorAlert field="EAN" title="Wrong EAN length" open={openIsWrongEANLength} />
      <ErrorAlert field="EAN" title="Wrong EAN control digit" open={openIsWrongControlDigit} />
      <ErrorAlert field="EAN" title="Wrong EAN symbols" open={openIsWrongEANSymbols} />
      <ErrorAlert field="EAN" title="Second EAN field for EAN codes that start with “23”, “2000”, “2097” is empty" open={openIsEAN2Empty} />
      <ErrorAlert field="EAN" title="EAN already exists" open={openIsNonUniqEAN} />

      <ErrorAlert field="descriptive_size_amount" title="Field “Nettokvantitet” (food) is empty" open={openIsDescriptiveSizeAmountEmpty} />
      <ErrorAlert field="descriptive_size_amount" title="Wrong unit" open={openIsWrongUnit} />

      {/* <ErrorAlert field="package_size" title="Field “Förpackningsstorlek”(non food) is empty" open={openIsPackageSizeEmpty} /> */}

      <ErrorAlert field="min_temperature" title="Temperature fields are empty" open={openIsNoTemperature} />
      <ErrorAlert field="trade_item_temperature_information" title="No degree character" open={openIsNoDegreeCharacter} />

      <ErrorAlert field="country_of_manufacturing" title="New field for “Tillverkningsland” (drop down field) is empty" open={openIsEmptyCountryOfManufacturing} />
  
      <ErrorAlert field="gross_weight_num" title="Bruttovikt field are empty" open={openIsGrossWeightEmpty} />
      <ErrorAlert field="gross_height" title="Fields for “Konsumentförpackningens dimensioner” are empty" open={openIsGrossDimensionsEmpty} />

      <ErrorAlert field="storeId" title="Store ID not defined" open={openIsEmptyStore} />
      
      <ErrorAlert field="ItemCategoriesOnline" title="Coop online categories are empty" open={openIsItemCategoriesOnlineEmpty} />

      <ErrorAlert field="energi_kj" title="Missing nutrient declaration" open={openIsNutritionalEmpty} />

   </div>
  );
};

export default Alerts;
