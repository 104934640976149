// Dependencies
import React, { useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { useQuery } from '@apollo/react-hooks';
import { path } from 'ramda';

// Components
import { ProductTabs } from '../../components/product';
import ProductCardList from '../../components/product/ProductCardList/ProductCardList';
import Breadcrumbs from '../../components/breadCrumbs/BreadCrumbs';

// Utils
import { addOrChangeQueryParam, pushToWindowHistory } from '../../utils/urlUtils';
import { getProductTabFromUrl } from '../../utils/products';
import { isDefined, parseBoolean } from '../../utils/helpers';

// Queries
import {
  PRODUCT_CARD_LIST_QUERY,
  PRODUCT_APPROVAL_SUMMARY,
  ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY,
} from '../../graphql/queries';
import ExcelExporter from '../../components/export/ExcelExporter';
import { IS_FOOD_LOCAL_STORAGE_KEY } from '../../constants/domain';
import { KeycloakContext } from '../../components/Secured';
import { useLocation } from 'react-router-dom';

const ProductTabsUpdates = ({ category, onTabChange, data, loading, error }) => {
  return (
    <>
      {loading && <></>}
      {!loading && error && <p>Could not load summary data...</p>}
      {isDefined(data) && <ProductTabs category={category} onTabChange={onTabChange} data={data} />}
    </>
  );
};

const ProductsPage = ({ match }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');
  const isAdmin = keycloakCtx.keycloak.hasResourceRole('admin');
  const location = useLocation();
  const itsMe = location?.pathname?.startsWith('/me/products');


  const category = getProductTabFromUrl();
  const isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
  const [state, setState] = React.useState({ category });

  useEffect(() => {
    // Listen on popstate events (back or forward browser event). Update tab-state with correct tab value.
    window.onpopstate = () => {
      const category = getProductTabFromUrl();
      setState({ category });
    };
    // Remove listener when unmount to avoid memory leakage.
    return () => {
      window.onpopstate = () => {};
    };
  });

  const variables = {};
  const queryVariables = { filter: { tagState: state.category } };
  if (!isAdmin) {
    queryVariables.includeDraft = true;
  }
  if(isFood != null) {
    const booleanIsFood = parseBoolean(isFood);
    variables.isFood = booleanIsFood;
    queryVariables.filter.isFood = booleanIsFood;
    queryVariables.isFood = booleanIsFood;
  } else if (isStore && !itsMe) {
    variables.isFood = true;
    variables.onlyApproved = true;
    queryVariables.filter.isFood = true;
    queryVariables.isFood = true;
    queryVariables.onlyApproved = true;
  }

  if (isAdmin) {
    variables.filterStoresByOrg = true;
  }
  if (isStore) {
    variables.filterStoresByParentOrg = true;
  }
  // Get Product Summary Info(Tab Info)
  const { data: summaryData, loading: summaryLoading, error: summaryError, refetch: summaryRefetch } = useQuery(PRODUCT_APPROVAL_SUMMARY, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const { data: notificationsData } = useQuery(ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  // When selecting new tab, update state and add proper param to URL for navigation purposes.
  const onTabChange = (category) => {
    const newURL = addOrChangeQueryParam('productTab', category, window.location);
    pushToWindowHistory(newURL, window, 'ProductTab');
    setState({ category });
  };

  const producerId = match?.params?.producerId;
  const notificationResponse = path(['productUpdateNotifications'], notificationsData);
  const productNotifications = isDefined(notificationResponse) ? notificationResponse : [];

  const title = isStore ? 'All Products' : 'Products';

  return (
    <>
      <Breadcrumbs breadCrumbs={[{ title: 'Home', linkTo: '/' }]} label={title} />
      <Paper>
        <ProductTabsUpdates
          data={summaryData}
          loading={summaryLoading}
          error={summaryError}
          category={state.category}
          onTabChange={onTabChange}
        />
        <Divider />
        <ExcelExporter selectedCategory={category} />
        {isDefined(summaryData) && (
          <ProductCardList
            showDescriptiveSize={true}
            category={state?.category}
            selectedProductId={match?.params?.id}
            producerId={producerId}
            productsQuery={PRODUCT_CARD_LIST_QUERY}
            queryVariables={{...queryVariables, filterStoresByOrg: isAdmin, filterStoresByParentOrg: isStore}}
            queryListDataExtractor={data => data?.products?.edges}
            summaryData={summaryData}
            currentTab={state.category}
            notificationsData={productNotifications}
            showSearch={true}
            showSorting={true}
            showPagination={true}
            summaryRefetch={summaryRefetch}
          />
        )}
      </Paper>
    </>
  );
};

// Export
export default ProductsPage;
