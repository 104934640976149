import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState, useContext } from 'react';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';

import { TExcelDataRow } from 'models/excel';
import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import { KeycloakContext } from 'components/Secured';
import { APPLICATION_ROLES, NON_FOOD_CATEGORY_ID, FOOD_CATEGORY_ID } from 'components/constants-ts';
import { LOCAL_STORE_ITEMS, PARSE_IMAGE_BY } from '../constants';
import { IImageFileSource, IImportColumn, IProductExcel } from '../types';
import {
  addRemovedProductIds,
  checkEnumType,
  computeBaseItems,
  correctColumnOrders,
  correctCorrespondingValues,
  getDataType,
  parseExcelDataToProduct,
} from '../utils/excel';
import {
  parseImageFile,
  readExcelDataFromIndexedDB,
  readImageFileDataFromIndexedDB,
  writeImageFileDataFromIndexedDB,
  testImageRegexString,
  sortImagesByName,
} from '../utils';
import { isNewNonFood } from 'components/product/ProductUpdateForm/fields/category';

import { ControlContext } from './ControlContext';
import { DataContext, TDataContextValue, TUploadedProductStatus } from './DataContext';

const switchDataValues = (
  data: IProductExcel,
  params: {
    oldColumn?: IImportColumn;
    newField: keyof IProduct;
    allCategories: IFoodlaCategoryOption[];
    onSwitchEan?: () => void;
  }
) => {
  const { oldColumn, newField, allCategories, onSwitchEan } = params;
  const newData = cloneDeep(data);

  if (!oldColumn) return newData;

  const valueOfNewField = newData[newField] as any;

  if (oldColumn.field) {
    if (oldColumn.field !== 'foodlaCategory') {
      newData[newField] = newData[oldColumn.field] as any;
    } else {
      // If the previous changes, user changed from a normal field (Ex: [title]) to [foodlaCategory]
      // Special case can happen: a cell has value, but it is not a [foodlaCategory] name
      // -> although this cell has value, but after changed to [foodlaCategory]
      //    this value will be set by "undefined" in [dataList] item
      // Because of this, when we change it from [foodlaCategory] to another field (Ex: [title]),
      //  We need get value from excel's column
      newData[newField] = newData._excelData[oldColumn.title] as any;
    }
  } else {
    // when the Header from excel's column is NOT valid
    //   -> can NOT map field, value
    //   -> field is undefined
    //   -> [dataList] items have NOT includes value of this excel's column
    // So when we change field, we have to get value from excel's column via [title]
    newData[newField] = newData._excelData[oldColumn.title] as any;
  }

  if (newField === 'foodlaCategory') {
    newData[newField] = allCategories.find(({ name }) => name === (newData[newField] as unknown as string));
  }

  newData[oldColumn.field] = valueOfNewField;

  if (newField === 'EAN') {
    newData[newField] = String(newData[newField] || '');
    onSwitchEan?.();
  }
  if (oldColumn.field === 'EAN') {
    newData[oldColumn.field] = String(newData[oldColumn.field] || '');
    onSwitchEan?.();
  }

  return newData;
};

interface DataProviderProps {
  rootCategory: IFoodlaCategoryOption;
  allCategories: IFoodlaCategoryOption[];
}

export const DataProvider: FC<DataProviderProps> = ({ rootCategory, allCategories, children }) => {
  const { keycloak } = useContext(KeycloakContext);
  const {
    step,
    userRole,
    indexedDB,
    selectedCategoryTypeId,
    categoryColumnList,
    productType,
    regexValue,
    imageSettings,
    setLoadingLocal,
    setUploadProgress,
    setConvertingImages,
    onProductTypeChange,
    onReset: onResetControl,
  } = useContext(ControlContext);

  // common questions
  const [commonQuestionData, setCommonQuestionData] = useState<IProduct>({});

  // excel
  const [isLoadedImageFromIndexDB, setLoadedImageFromIndexDB] = useState(false);
  const [isLoadedSavedProduct, setLoadedSavedProduct] = useState(false);
  const [dataList, setDataList] = useState<IProductExcel[]>([]);
  const [excelColumnList, setExcelColumnList] = useState<IImportColumn[]>([]);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [excelFileData, setExcelFileData] = useState<TExcelDataRow[]>([]);
  const [uploadedProductStatusSet, setUploadedProductStatusSet] = useState<Record<string, TUploadedProductStatus>>({});
  const [isReseted, setReseted] = useState(false);

  // product images
  const [editingImageProductData, setEditingImageProductData] = useState<IProductExcel | null>(null);
  const [matchedImageFileDataList, setMatchedImageFileDataList] = useState<IImageFileSource[]>([]);
  const [allImageFileDataList, setAllImageFileDataList] = useState<IImageFileSource[]>([]);
  const [productImageDataSet, setProductImageDataSet] = useState<Record<string, IImageFileSource[]>>({});
  const [tempApproveEanStatus, setTempApproveEanStatus] = useState<Record<string, string>>({});

  const { columnList: defaultExcelColumnList, dataList: defaultDataList } = useMemo(() => {
    return parseExcelDataToProduct({
      selectedCategoryTypeId,
      excelData: excelFileData || [],
      categoryColumnList,
      allCategories,
      rootCategory,
    });
  }, [selectedCategoryTypeId, allCategories, rootCategory, excelFileData, categoryColumnList]);

  const isUploadedSuccessfully = useMemo(() => {
    if (!Object.keys(uploadedProductStatusSet).length) return false;
    return Object.values(uploadedProductStatusSet).every(({ error }) => !error);
  }, [uploadedProductStatusSet]);

  const onChangeDataValue = useCallback((id: string, field: keyof IProduct, value: string) => {
    const dataType = getDataType(field);

    setDataList(oldDataList => {
      const newDataList = cloneDeep(oldDataList);
      const index = newDataList.findIndex(item => item.id === id);
      if (index > -1) {
        let newValue: string | Date = value;
        if (dataType === 'date') newValue = new Date(value);
        newDataList[index] = { ...newDataList[index], [field]: newValue };
        newDataList[index]._editingData = { ...newDataList[index]._editingData, [field]: newValue };
      }
      return computeBaseItems(newDataList);
    });
  }, []);

  const onChangeAllDataValue = useCallback((field: keyof IProduct, value: string, options?: { idList?: string[] }) => {
    // currently, support for "Bolean" only
    setDataList(oldDataList => {
      const newDataList = oldDataList.map(data => {
        if (options?.idList?.length) {
          if (!options.idList.includes(data.id || '')) return data;
        }

        let newData = cloneDeep(data);
        newData = { ...newData, [field]: value };
        newData._editingData = { ...newData._editingData, [field]: value };
        return newData;
      });
      return computeBaseItems(newDataList);
    });
  }, []);

  const onRemoveData = useCallback((id: string) => {
    setDataList(oldDataList => {
      const data = oldDataList.find(data => data.id === id);
      if (data && !isNil(data._index)) {
        addRemovedProductIds(data._index);
      }
      return oldDataList.filter(data => data.id !== id);
    });
  }, []);

  const onRemoveColumn = useCallback((id: string) => {
    setExcelColumnList(oldColumnList => {
      const removingColumn = oldColumnList.find(column => column._id === id);

      setDataList(oldDataList =>
        oldDataList.map(data => {
          const newData = cloneDeep(data);
          if (removingColumn && !newData[removingColumn?.field] !== undefined) {
            newData[removingColumn?.field] = undefined;
          }
          return newData;
        })
      );

      return oldColumnList.filter(column => column._id !== id);
    });
  }, []);

  const onUpdateColumnField = useCallback(
    (id: string, newField: keyof IProduct) => {
      setExcelColumnList(oldColumnList => {
        const oldColumn = oldColumnList.find(column => column._id === id);

        // change field in dataList
        setDataList(oldDataList => {
          let newDataList = cloneDeep(oldDataList);
          newDataList = newDataList.map(data => {
            const newData = switchDataValues(data, {
              oldColumn,
              newField,
              allCategories,
              onSwitchEan: () => setLoadedSavedProduct(false),
            });

            if (oldColumn && newData._editingData) {
              const oldField = oldColumn.field;
              const oldEditedValue = newData._editingData[oldField];

              delete newData._editingData[oldField];
              if (!isNil(newData._editingData?.[newField]))
                (newData._editingData[oldField] as any) = newData._editingData?.[newField];

              delete newData._editingData[newField];
              if (!isNil(oldEditedValue)) (newData._editingData[newField] as any) = oldEditedValue;
            }

            return newData;
          });

          return correctCorrespondingValues(newDataList);
        });

        const fromIndex = oldColumnList.findIndex(column => column._id === id);
        const toIndex = oldColumnList.findIndex(column => column.field === newField);

        let newColumnList = cloneDeep(oldColumnList);
        if (fromIndex > -1) {
          const oldField = newColumnList[fromIndex].field;

          const categoryColumnForOld = categoryColumnList.find(({ field }) => field === newField);
          if (categoryColumnForOld) {
            newColumnList[fromIndex] = {
              ...newColumnList[fromIndex],
              field: newField,
              dataType: getDataType(newField),
              isEnumType: checkEnumType(newField),
              isExtra: !!categoryColumnForOld.isExtra,
              isMandatory: !!categoryColumnForOld.isMandatory,
              defaultValue: !!categoryColumnForOld.defaultValue,
            };
          } else {
            delete newColumnList[fromIndex];
          }

          const categoryColumnForNew = categoryColumnList.find(({ field }) => field === oldField);
          if (toIndex > -1) {
            if (categoryColumnForNew) {
              newColumnList[toIndex] = {
                ...newColumnList[toIndex],
                field: oldField,
                dataType: getDataType(oldField),
                isEnumType: checkEnumType(oldField),
                isExtra: !!categoryColumnForNew.isExtra,
                isMandatory: !!categoryColumnForNew.isMandatory,
                defaultValue: !!categoryColumnForNew.defaultValue,
              };
            } else {
              newColumnList[toIndex] = {
                ...newColumnList[toIndex],
                field: oldField,
                dataType: 'string',
                isEnumType: false,
                isExtra: false,
                isMandatory: false,
              };
            }
          }
        }
        // remove deleted columns
        newColumnList = newColumnList.filter(column => !!column);

        return correctColumnOrders(newColumnList);
      });
    },
    [categoryColumnList, allCategories]
  );

  const onUploadedProductStatusSetChange = useCallback((set: Record<string, TUploadedProductStatus>) => {
    setUploadedProductStatusSet(set);
    localStorage.setItem(LOCAL_STORE_ITEMS.UPLOADED_PRODUCT_STATUS_SET, JSON.stringify(set));
  }, []);

  const updateMatchedImageFileData = useCallback(
    (params: { imageDataList?: IImageFileSource[]; regex?: string; aiIgnore?: boolean }) => {
      const imageDataList = params.imageDataList || allImageFileDataList;
      const aiIgnore = params.aiIgnore || imageSettings.aiIgnore;
      const regex = params.regex || regexValue;

      if ([APPLICATION_ROLES.PRODUCER, APPLICATION_ROLES.STORE].includes(userRole)) {
        setMatchedImageFileDataList(imageDataList);
        return;
      }

      const newList = imageDataList.filter(({ name, assignProductId }) => {
        if (aiIgnore && name.endsWith('AIGENERATED')) return false;
        if (assignProductId) return true;
        return testImageRegexString(regex, imageSettings.parseImageBy, name);
      });

      setMatchedImageFileDataList(newList);
    },
    [userRole, allImageFileDataList, regexValue, imageSettings.aiIgnore, imageSettings.parseImageBy]
  );

  const onAllImageFileDataChange = useCallback(
    (callback: (oldImageDataList: IImageFileSource[]) => IImageFileSource[]) => {
      return new Promise<void>(resolve => {
        setAllImageFileDataList(state => {
          const imageDataList = callback(state);
          updateMatchedImageFileData({ imageDataList });
          if (indexedDB) {
            const list = imageDataList.filter(item => !item.isFromProduct);
            writeImageFileDataFromIndexedDB(indexedDB, list).then(() => resolve());
          }
          return imageDataList;
        });
      });
    },
    [indexedDB, updateMatchedImageFileData]
  );

  const onProductImageChange = useCallback(
    async (fileList: File[]) => {
      const promises = fileList.map(async imageFile =>
        parseImageFile({
          file: imageFile,
          token: keycloak?.token || '',
          startCallback: () => setConvertingImages(true),
        })
      );
      const newImageFileData = await Promise.all(promises);
      setUploadProgress({ total: 0, uploadedTotal: 0, isUploading: false, uploaded: [], uploading: [] });
      await onAllImageFileDataChange(oldImageList => [...oldImageList, ...newImageFileData]);
      setConvertingImages(false);
    },
    [keycloak, setUploadProgress, setConvertingImages, onAllImageFileDataChange]
  );

  const onReset = useCallback(
    async (isNotAll?: boolean) => {
      setReseted(true);
      onResetControl(isNotAll);

      setExcelFile(null);
      setExcelFileData([]);
      setUploadedProductStatusSet({});

      setCommonQuestionData({});
      setEditingImageProductData(null);
      setMatchedImageFileDataList([]);
      setAllImageFileDataList([]);
      setProductImageDataSet({});
      setTempApproveEanStatus({});
    },
    [onResetControl]
  );

  const value = useMemo<TDataContextValue>(
    () => ({
      rootCategory,
      allCategories,

      commonQuestionData,
      setCommonQuestionData,

      // excel
      excelFile,
      setExcelFile,
      excelFileData,
      setExcelFileData,

      isLoadedImageFromIndexDB,
      isLoadedSavedProduct,
      setLoadedSavedProduct,
      dataList,
      setDataList,
      onChangeDataValue,
      onChangeAllDataValue,
      onRemoveData,
      isUploadedSuccessfully,
      uploadedProductStatusSet,
      onUploadedProductStatusSetChange,
      excelColumnList,
      onRemoveColumn,
      onUpdateColumnField,

      // product images
      editingImageProductData,
      setEditingImageProductData,
      matchedImageFileDataList,
      allImageFileDataList,
      onAllImageFileDataChange,
      productImageDataSet,
      setProductImageDataSet,
      onProductImageChange,
      tempApproveEanStatus,
      setTempApproveEanStatus,
      isReseted,
      onReset,
    }),
    [
      rootCategory,
      allCategories,

      commonQuestionData,

      excelFile,
      excelFileData,
      dataList,

      isLoadedImageFromIndexDB,
      isLoadedSavedProduct,
      onChangeDataValue,
      onChangeAllDataValue,
      onRemoveData,
      isUploadedSuccessfully,
      uploadedProductStatusSet,
      onUploadedProductStatusSetChange,
      excelColumnList,
      onRemoveColumn,
      onUpdateColumnField,
      editingImageProductData,
      matchedImageFileDataList,
      allImageFileDataList,
      onAllImageFileDataChange,
      productImageDataSet,
      onProductImageChange,
      tempApproveEanStatus,
      isReseted,
      onReset,
    ]
  );

  useEffect(() => {
    if (excelFile) {
      setReseted(false);
    }
  }, [excelFile]);

  useEffect(() => {
    if (step !== 4) {
      setLoadedSavedProduct(false);
    }
  }, [step]);

  // set default datalist
  useEffect(() => {
    setDataList(defaultDataList);
  }, [defaultDataList]);

  // set default excelColumnList
  useEffect(() => {
    setExcelColumnList(defaultExcelColumnList);
  }, [defaultExcelColumnList]);

  useEffect(() => {
    updateMatchedImageFileData({ aiIgnore: imageSettings.aiIgnore });
  }, [imageSettings.aiIgnore, updateMatchedImageFileData]);

  useEffect(() => {
    updateMatchedImageFileData({ regex: regexValue });
  }, [regexValue, updateMatchedImageFileData]);

  useEffect(() => {
    const keyIdMap = new Map();
    let fieldMatcherList = [imageSettings.parseImageBy] as (keyof IProduct)[];
    if ([APPLICATION_ROLES.PRODUCER, APPLICATION_ROLES.STORE].includes(userRole)) {
      fieldMatcherList = [PARSE_IMAGE_BY.ARTICLE, PARSE_IMAGE_BY.EAN];
    }
    dataList.forEach(data => {
      fieldMatcherList.forEach(field => {
        if (data.id && data[field]) keyIdMap.set(String(data[field]), String(data.id));
      });
    });

    const acceptedImageDataSet: Record<string, IImageFileSource[]> = {};
    matchedImageFileDataList.forEach(imageFileItem => {
      let productId = imageFileItem.assignProductId;
      for (let [fieldValue, id] of keyIdMap.entries() as unknown as [string, string][]) {
        if (!productId && imageFileItem.name.startsWith(fieldValue)) {
          productId = id;
        }
      }
      if (!productId) return;
      if (!acceptedImageDataSet[productId]) {
        acceptedImageDataSet[productId] = [];
      }
      acceptedImageDataSet[productId].push(imageFileItem);
    });

    // order images
    const orderedImageDataSet: Record<string, IImageFileSource[]> = {};
    Object.entries(acceptedImageDataSet).forEach(([productId, images]) => {
      orderedImageDataSet[productId] = sortImagesByName(images);
    });

    setProductImageDataSet(orderedImageDataSet);
  }, [userRole, indexedDB, dataList, matchedImageFileDataList, imageSettings.parseImageBy, imageSettings.aiMain]);

  // detect foodlaCategory
  useLayoutEffect(() => {
    if (!productType && dataList.length) {
      const isAllNonFood = dataList.every(({ foodlaCategory }) => isNewNonFood(foodlaCategory, rootCategory));
      onProductTypeChange(isAllNonFood ? NON_FOOD_CATEGORY_ID : FOOD_CATEGORY_ID);
    }
  }, [rootCategory, dataList, productType, onProductTypeChange]);

  // load saved uploaded product id list
  useEffect(() => {
    try {
      const newUploadedProductIdSet = JSON.parse(
        localStorage.getItem(LOCAL_STORE_ITEMS.UPLOADED_PRODUCT_STATUS_SET) || '{}'
      );
      setUploadedProductStatusSet(newUploadedProductIdSet);
    } catch {
      setUploadedProductStatusSet({});
    }
  }, []);

  // load saved excel data
  useEffect(() => {
    if (!indexedDB) return;
    readExcelDataFromIndexedDB(indexedDB).then(({ excelFile, excelFileData }) => {
      setExcelFile(excelFile);
      setExcelFileData(excelFileData);
      setLoadingLocal(false);
    });
  }, [indexedDB, setExcelFile, setExcelFileData, setLoadingLocal]);

  // load saved product images
  useEffect(() => {
    if (!indexedDB || isLoadedImageFromIndexDB) return;
    readImageFileDataFromIndexedDB(indexedDB).then(imageDataList => {
      setAllImageFileDataList(imageDataList);
      updateMatchedImageFileData({ imageDataList });
      setLoadedImageFromIndexDB(true);
    });
  }, [indexedDB, isLoadedImageFromIndexDB, updateMatchedImageFileData]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
