import { FC, useEffect, useRef, useState } from 'react';

import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from 'components/button';
import { useHistory, useLocation } from 'react-router-dom';

export type TRouteLocation = {
  hash: string;
  pathname: string;
  search: string;
};

interface LeavePagePromptProps {
  message: string;
  when: (options: { oldLocation: TRouteLocation; newLocation?: TRouteLocation; nativeUnload?: boolean }) => boolean;
}

const LeavePagePrompt: FC<LeavePagePromptProps> = ({ message, when }) => {
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [intendedPath, setIntendedPath] = useState('');

  const keepRef = useRef({ when, location });
  keepRef.current.when = when;
  keepRef.current.location = location;

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    history.block(() => {});
    setOpen(false);
    history.push(intendedPath);
  };

  useEffect(() => {
    history.block(newLocation => {
      if (keepRef.current.when({ newLocation, oldLocation: keepRef.current.location })) {
        setIntendedPath(newLocation.pathname);
        setOpen(true);
        return false;
      }
    });

    return () => {
      history.block(() => {});
    };
  }, [history]);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (keepRef.current.when({ oldLocation: keepRef.current.location, nativeUnload: true })) {
      window.addEventListener('beforeunload', unloadCallback);
    }
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [message]);

  return (
    <Dialog open={open}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Box display="flex" gridGap={16} px={2} py={1}>
          <Button size="small" variant="outlined" onClick={handleAccept}>
            Ja
          </Button>
          <Button size="small" variant="contained" onClick={handleCancel}>
            Stanna på sidan
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LeavePagePrompt;
