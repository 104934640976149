import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

// import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ProductCardList from '../../components/product/ProductCardList/ProductCardList';
import { ALL_PRODUCTS_PAGINATION_QUERY } from '../../graphql/queries';

const styles = theme => ({
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
});


const SuperuserAllProducts = ({ classes, match, history }) => {
    return (
        <Paper>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.toolbarBox}>
                    <Typography variant="body1" className={classes.title}>
                        All Products
                    </Typography>
                </Box>
            </Toolbar>
            <Divider />
            <ProductCardList
                showDescriptiveSize={true}
                showSearch={true}
                showSorting={true}
                showPagination={true}
                selectedProductId={match?.params?.id}
                productsQuery={ALL_PRODUCTS_PAGINATION_QUERY}
                queryVariables={{ includeDraft: true }}
                overrideOnClickCallback={productClicked => {
                    const pushtoLink = `/superuser/products/${productClicked.id}/approval`;
                    history.push(pushtoLink);
                }}
            />
        </Paper>
    );
};

export default withStyles(styles)(SuperuserAllProducts);
