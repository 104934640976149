import React, { useContext } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import InputLabel from '../../../inputfields/InputLabel';
import FloatInput from '../FloatInput';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import InputTextField from '../../../inputfields/InputTextField';
import IProduct from '../../../../models/product';
import { KeycloakContext } from 'components/Secured';
import useViewport from 'hooks/useViewports';

interface INutritionProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
  classes?: any;
}

const styles = (theme: any) => ({
  asterisk: {
    color: theme.palette.error.main,
  },
});

export const Nutrition = ({ state, setState, classes, approve }: INutritionProps) => {
  const { mdUp } = useViewport();

  // TODO any
  const handleChange = (event: any) => {
    const stateName = event.target.name;
    let stateValue = event.target.value;

    setState({ ...state, [stateName]: stateValue });
  };

  const labelStyle: React.CSSProperties = { flex: mdUp ? '0 0 200px' : '0 0 175px' };
  const inputStyle: React.CSSProperties = { flex: mdUp ? '0 0 150px' : 1 };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', flex: 1 }}>
        <h3 style={{ ...(mdUp ? labelStyle : {}), marginTop: 9, marginBottom: mdUp ? 9 : 0 }}>
          Näringsvärde per 100
        </h3>

        <FormControl component="fieldset">
          <RadioGroup row aria-label="nutritional_unit" value={state.nutritional_unit || "GRAM"} name="nutritional_unit" onChange={handleChange}>
              <FormControlLabel
                value="GRAM"
                control={<Radio color="primary" />}
                label="gram"
                labelPlacement="start"
                style={{ marginLeft: 0 }}
              />
              <FormControlLabel
                value="MILLILITER"
                control={<Radio color="primary" />}
                label="milliliter"
                labelPlacement="start"
              />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', flex: 1 }}>
        <h3 style={mdUp ? labelStyle : { flex: 0, marginBottom: 0 }}>
          Energi<span className={classes.asterisk}>*</span>:
        </h3>
        <div style={{ flex: 1, display: 'flex', gap: 4 }}>
          <FloatInput
            name="energi_kj"
            // requiredNutritional={true}
            required={true}
            state={state}
            setState={setState}
            style={inputStyle}
          />
          <h3>&nbsp;kJ /&nbsp;</h3>

          <FloatInput
            name="energi_kcal"
            required={true}
            // requiredNutritional={true}
            state={state}
            setState={setState}
            style={inputStyle}
          />
          <h3>&nbsp;kcal</h3>
        </div>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Fett<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="fett"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <h3 style={{marginBottom: 2, marginTop: 2}}>
        &nbsp;&nbsp;&nbsp;&nbsp;varav:
      </h3>

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- mättat fett<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="mattatFett"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- enkelomättat fett:
        </h3>
        <FloatInput
          name="enkelomattatFett"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- fleromättat fett:
        </h3>
        <FloatInput
          name="flerromattatFett"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
        <Divider style={{flex: 0.9}} />
        {approve && state?.approve?.find((x) => x.field === 'nutrition') && (
          <ThreeWaySwitch
            style={{ flex: 0.1, marginBottom: 8 }}
            name="nutrition"
            state={state}
            setState={setState}
          />
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
        Kolhydrat<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="kolhydrat"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <h3 style={{marginBottom: 2, marginTop: 2}}>
        &nbsp;&nbsp;&nbsp;&nbsp;varav:
      </h3>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- sockerarter<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="sockerarter"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- polyoler:
        </h3>
        <FloatInput
          name="polyoler"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- stärkelse:
        </h3>
        <FloatInput
          name="starkelse"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Fiber:
        </h3>
        <FloatInput
          name="fiber"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Protein<span className={classes.asterisk}>*</span>:
        </h3>

        <FloatInput
          name="protein"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Salt<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="salt"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />
    </>
  );
};

const NutritionBase = withStyles(styles)(Nutrition);

const validValue = (value?: number) => {
  return !!value || value === 0;
}

const NutritionWrapper = ({ state, ...props }: INutritionProps) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');

  const expand = isStore && (
    validValue(state.energi_kj) ||
    validValue(state.energi_kcal) ||
    validValue(state.fett) ||
    validValue(state.mattatFett) ||
    validValue(state.enkelomattatFett) ||
    validValue(state.flerromattatFett) ||
    validValue(state.kolhydrat) ||
    validValue(state.sockerarter) ||
    validValue(state.polyoler) ||
    validValue(state.starkelse) ||
    validValue(state.fiber) ||
    validValue(state.protein) ||
    validValue(state.salt)
  );

  const labelElement = (
    <InputLabel
      heading="Näringsdeklaration"
      text="Obligatorisk, i de flesta fall, men det finns några undantag. Den obligatoriska näringsdeklarationen ska innehålla uppgifter om energivärde, och mängden av fett, mättat fett, kolhydrat, sockerarter, protein och salt."
      link="http://kontrollwiki.livsmedelsverket.se/artikel/46/naringsdeklaration"
    />
  );

  if (!isStore) {
    return (
      <>
        {labelElement}
        <NutritionBase {...props} state={state} />
      </>
    );
  }

  return (
    <Box my={1}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expand ? ["root"] : undefined}
      >
        <TreeItem nodeId="root" label={labelElement}>
          <NutritionBase {...props} state={state} />
        </TreeItem>
      </TreeView>
    </Box>
  );
}

export default NutritionWrapper;
