import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import Viewer from 'react-viewer';

import Container from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { path, map } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Query } from 'react-apollo';
import { ReplayControl } from 'video-react';

import { Statistic } from '../Statistic';
import { ApproveButton } from '.';
import { KeycloakContext } from '../Secured';
import { GET_PRODUCT_BY_ID } from '../../graphql/queries';
import { IMAGE_SIZES, getBlobDataFromImageURL, getImageWithSizeSuffix } from 'utils/images2';
import { isDefined } from '../../utils/helpers';
import { isNonFoodProduct, transformCategoryRead } from 'utils/products';
import {
  MAIN_LABELS,
  ECO_LABELS,
  VEGAN_LABELS,
  ENVIRONMENTAL_LABELS,
  getDescriptiveSizeValueFromEnum,
} from '../constants';
import { optionalAlcoholPercentage } from '../constants-ts';

import 'video-react/dist/video-react.css';
import HorizontalImageList from '../images/HorizontalImageList';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    backgroundColor: '#f5f5f5',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  heading: {
    color: 'darkgrey',
  },
}));

const buildNutritional = (product) => {
  let arr = [];
  if (isDefined(product.energi_kj) || isDefined(product.energi_kcal)) {
    arr.push(`Energi: ${product.energi_kj || "???"} kJ/${product.energi_kcal || "???"} kcal`);
  }

  if (isDefined(product.fett) || isDefined(product.mattatFett) || isDefined(product.enkelomattatFett) || isDefined(product.flerromattatFett)) {
    if (isDefined(product.fett)) {
      arr.push(`Fett: ${product.fett} g`);
    } else {
      arr.push(`Fett:`);
    }
    if (isDefined(product.mattatFett) || isDefined(product.enkelomattatFett) || isDefined(product.flerromattatFett)) {
      arr.push("    varav:");
    }
    if (isDefined(product.mattatFett)) {
      arr.push(`    - mättat fett: ${product.mattatFett} g`);
    }
    if (isDefined(product.enkelomattatFett)) {
      arr.push(`    - enkelomättat fett: ${product.enkelomattatFett} g`);
    }
    if (isDefined(product.flerromattatFett)) {
      arr.push(`    - fleromättat fett: ${product.flerromattatFett} g`);
    }
  }


  if (isDefined(product.kolhydrat) || isDefined(product.sockerarter) || isDefined(product.polyoler) || isDefined(product.starkelse)) {
    if (isDefined(product.kolhydrat)) {
      arr.push(`Kolhydrat: ${product.kolhydrat} g`);
    } else {
      arr.push(`Kolhydrat:`);
    }
    if (isDefined(product.sockerarter) || isDefined(product.polyoler) || isDefined(product.starkelse)) {
      arr.push("    varav:");
    }
    if (isDefined(product.sockerarter)) {
      arr.push(`    - sockerarter: ${product.sockerarter} g`);
    }
    if (isDefined(product.polyoler)) {
      arr.push(`    - polyoler: ${product.polyoler} g`);
    }
    if (isDefined(product.starkelse)) {
      arr.push(`    - stärkelse: ${product.starkelse} g`);
    }
  }
  if (isDefined(product.fiber)) {
    arr.push(`Fiber: ${product.fiber} g`);
  }
  if (isDefined(product.protein)) {
    arr.push(`Protein: ${product.protein} g`);
  }
  if (isDefined(product.salt)) {
    arr.push(`Salt: ${product.salt} g`);
  }

  let unit = 'g';
  if (isDefined(product.nutritional_unit)) {
    if (product.nutritional_unit === 'GRAM') {
      unit = 'g';
    } else if (product.nutritional_unit === 'MILLILITER') {
      unit = 'ml';
    }
  }
  if (arr && arr.length > 0) {
    if (arr.length === 1 && false) {
      arr = ["", ...arr];
    } else {
      arr = ["", `Näringsvärde per 100 ${unit}:`, ...arr];
    }
  }
  return arr.join("\n");
};

const StaticGoogleMap = ({ center, zoom, size, scale, format, maptypes, location }) => {
  return (
    <Box>
      <a href={`https://www.google.com/maps/place/${center.latitude},${center.longitude}`}>
        <img
          style={{ width: '100%', height: 'auto' }}
          src={`https://maps.googleapis.com/maps/api/staticmap?zoom=10&scale=1&markers=${center.latitude},${center.longitude}&size=${size}&maptype=roadmap&format=png&visual_refresh=true&key=AIzaSyCynZHuc1ZHHN_d9vGK3Nk97NPQaBg0wSQ`}
          alt="Google Map of Product Location"
        />
      </a>
    </Box>
  );
};

const Show = props => {
  const keycloakCtx = useContext(KeycloakContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    viewerVisible: false,
    activeIndex: 0,
  });

  const handleClose = () => {
    setState({ viewerVisible: false });
  };

  let images = [
    {
      src: props?.data?.pictures?.large?.url
        ? props.data.pictures.large.url.substr(0, props.data.pictures.large.url.indexOf('?'))
        : '',
    },
  ];
  const downloadImage = async src => {
    const originalImageUrl = src.split('?')[0];
    const blob = await getBlobDataFromImageURL(originalImageUrl);
    let fileName = originalImageUrl.substr(originalImageUrl.lastIndexOf('/') + 1);

    const aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);
    aLink.target = '_blank';
    aLink.className = 'J_notOpenInContainer';
    document.body.appendChild(aLink);
    aLink.click();
    URL.revokeObjectURL(aLink.href);
  };

  const mainImageUrl = path(['data', 'pictures', 'large', 'url'], props);
  const additionalImages = path(['data', 'additionalImages'], props);

  let imageUrlsForSlider = [mainImageUrl];

  if (isDefined(additionalImages)) {
    const urls = map(ai => getImageWithSizeSuffix(ai, IMAGE_SIZES.MEDIUM), additionalImages);
    imageUrlsForSlider = imageUrlsForSlider.concat(urls);
  }

  let descriptive_size_string = "";
  // don't show if no field in db
  if (props.data.descriptive_size_amount !== null && props.data.descriptive_size_amount !== undefined) {
    let unit = getDescriptiveSizeValueFromEnum(props.data.descriptive_size_unit);
    let dsa = [props.data.descriptive_size_amount, ...(props.data.descriptive_size_amount_extra || [])].join(", ");
    descriptive_size_string = `${dsa} ${unit}`;
  }

  const getLabelTitle = (name) => {
    const item = [...MAIN_LABELS, ...ECO_LABELS, ...VEGAN_LABELS, ...ENVIRONMENTAL_LABELS].find((x) => x.field === name);
    if (item) {
      return item.title
    }
    return name;
  };

  const labels = props?.data?.labels?.filter((x) => x.state)
    .map((x) => getLabelTitle(x.field))
    .join(', ');
  console.log('!labels', labels);

  const isNonFood = isNonFoodProduct(props?.data);
  const isFood = !isNonFood;

  const nutritional = buildNutritional(props.data);
  
  return (
    <Container className={classes.container}>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>Produktnamn</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>{props.data.name}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>Produktbilder</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HorizontalImageList
              imageUrls={imageUrlsForSlider}
              height={256}
              withActionButtons
              onClickActionButton={src => downloadImage(src)}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>EAN-kod</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {props.data.EAN && (
            <Typography display={'block'} gutterBottom component="div" style={{ whiteSpace: 'pre-wrap' }}>
              {props.data.EAN}
            </Typography>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={!keycloakCtx.keycloak.hasResourceRole('producer')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>Producent</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography display={'inline'} gutterBottom>
            <Link component={RouterLink} variant="inherit" to={`/producers/${props.data.producer.id}/show`}>
              {props.data.producer.name}
            </Link>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>Produktinformation</Typography>
        </ExpansionPanelSummary>
        { isFood &&
          <ExpansionPanelDetails>
            <Typography display={'block'} gutterBottom component="div" style={{ whiteSpace: 'pre-wrap' }}>
              <p>
                <b>Kategori</b>: {props.data.foodlaCategory?.name || ''}
              </p>
              {isDefined(props.data.classification) && (
                <p>
                  <b>Klassifikation</b>: {props.data.classification}
                </p>
              )}
              {optionalAlcoholPercentage(props.data.foodlaCategory) && isDefined(props.data.alcoholPercentage) && (
                <p>
                  <b>Alkoholhalt:</b> {props.data.alcoholPercentage} %
                </p>
              )}
              <p>
                <b>Produktbeskrivning</b>: {props.data.description}
              </p>
              <p>
                <b>Ingrediensförteckning</b>: {props.data.ingredient_statement}
              </p>
              {labels && (
                <p>
                  <b>Märkningar</b>: {labels}
                </p>
              )}
              {nutritional && nutritional.trim().length > 5 && isFood && (
                <p>
                  <b>Näringsdeklaration</b>: {nutritional}
                </p>
              )}
              <p>
                <b>Nettokvantitet</b>: {descriptive_size_string}
              </p>
              {isDefined(props.data.trade_item_temperature_information) && (
                <p>
                  <b>Förvaringsanvisning</b>: {props.data.trade_item_temperature_information}
                </p>
              )}
              <p>
                <b>Ursprungsland och härkomstplats</b>: {props.data.place_of_item_activity}
              </p>
              {isDefined(props.data.additional_required_information) && (
                <p>
                  <b>Ytterligare obligatoriska uppgifter</b>: {props.data.additional_required_information}
                </p>
              )}
              {isDefined(props.data.brand_food) && (
                <p>
                  <b>Varumärke</b>: {props.data.brand_food}
                </p>
              )}
            </Typography>
          </ExpansionPanelDetails>
        }
        { isNonFood &&
          <ExpansionPanelDetails>
            <Typography display={'block'} gutterBottom component="div" style={{ whiteSpace: 'pre-wrap' }}>
              <p>
                <b>Kategori</b>: {props.data.foodlaCategory?.name || ''}
              </p>
              <p>
                <b>Produktbeskrivning</b>: {props.data.description}
              </p>
              {props.data.color && props.data.color.trim() !== '' && (
                <p>
                  <b>Färg</b>: {props.data.color}
                </p>
              )}
              {props.data.material && props.data.material.trim() !== '' && (
                <p>
                  <b>Material</b>: {props.data.material}
                </p>
              )}
              {props.data.non_food_ingredients && props.data.non_food_ingredients.trim() !== '' && (
                <p>
                  <b>Ingrediensförteckning</b>: {props.data.non_food_ingredients}
                </p>
              )}
              {props.data.dimensions && props.data.dimensions.trim() !== '' && (
                <p>
                  <b>Mått</b>: {props.data.dimensions}
                </p>
              )}
              {props.data.capacity && props.data.capacity.trim() !== '' && (
                <p>
                  <b>Volym</b>: {props.data.capacity}
                </p>
              )}
              {props?.data?.gross_weight_num && (
                <p>
                  <b>Bruttovikt</b>: {props.data.gross_weight_num.toString()} gram
                </p>
              )}
              {/* <p>
                <b>Förpackningsstorlek</b>: {props.data.package_size} {props.data.package_size_unit}
              </p> */}
              {/* TODO replace with country_of_manufacturing */}
              {/* {props?.data?.country_of_origin && (
                <p>
                  <b>Tillverkningsland</b>: {props.data.country_of_origin}
                </p>
              )} */}
              <p>
                <b>Varumärke</b>: {props.data.brand}
              </p>
            </Typography>
          </ExpansionPanelDetails>
        }
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>B-pack storlek (Kfp/Dfp)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {props?.data?.consumer_size_to_order_size_ratio && (
            <Typography display={'block'} gutterBottom component="div" style={{ whiteSpace: 'pre-wrap' }}>
              {props.data.consumer_size_to_order_size_ratio}
            </Typography>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>Plats/Location</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box>
            <StaticGoogleMap
              style={{ borderRadius: '4px' }}
              size="400x200"
              center={{
                latitude: props.data.latitude,
                longitude: props.data.longitude,
              }}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>Statistik</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" wrap="nowrap" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Statistic
                ean={props.data.EAN} 
                columns={["checkouts", "revenue", "listView"]} 
                showListViewChart={true}  
                showQuantityChart={true}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {(keycloakCtx.keycloak.hasResourceRole('admin')
        || keycloakCtx.keycloak.hasResourceRole('store')) && (
        <ExpansionPanel defaultExpanded={false}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className={classes.heading}>Status</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              <div>
                <Typography>{props.data.state.state}</Typography>
              </div>
              {keycloakCtx.keycloak.hasResourceRole('admin') && (
                <div style={{ paddingTop: '10px' }}>
                  <ApproveButton
                    size="medium"
                    productId={props.data.id}
                    producerId={props.data.producer.id}
                    classes={classes}
                    productState={props.data.state.state}
                    usedByProducerPage={props.producerId != null}
                    refetch={props?.refetch}
                  />
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <Viewer
        zIndex={10000}
        visible={state.viewerVisible}
        onClose={handleClose}
        images={images}
        activeIndex={state.activeIndex}
      />
    </Container>
  );
};

const ModalShowController = ({ productId, producerId }) => (
  <Query query={GET_PRODUCT_BY_ID} variables={{ id: productId }}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
      return <Show data={transformCategoryRead(data.product)} producerId={producerId} refetch={refetch} />;
    }}
  </Query>
);

ReplayControl.propTypes = {
  // How many seconds to go forward
  // default: 10
  seconds: PropTypes.oneOf([5, 10, 30]),
};

export default ModalShowController;
