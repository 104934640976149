import React, { useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  ALL_PRODUCTS_PAGINATION_QUERY,
} from '../../graphql/queries';

import ProductCardList from '../../components/product/ProductCardList/ProductCardList';
import { KeycloakContext } from '../../components/Secured';

const styles = theme => ({
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
});

const UserProductsPage = withStyles(styles)(({ classes, match }) => {
    const itsMe = match?.url?.startsWith('/me/products');
    // const { data: notificationsData } = useQuery(ORGANIZATION_ALL_PRODUCTS_UPDATE_NOTIFICATIONS_QUERY, {
    //     fetchPolicy: 'cache-and-network',
    // });
    // console.log('!!!notificationsData', notificationsData);
    // const producerId = match?.params?.producerId;
    // const notificationResponse = path(['allProductsUpdateNotifications'], notificationsData);
    // const productNotifications = isDefined(notificationResponse) ? notificationResponse : [];
    // console.log('!!!productNotifications', productNotifications);
    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
    const addProductUrl = itsMe ? '/me/products/addproduct' : '/products/addproduct';
    // Get Product Summary Info(Tab Info)

    return (
        <Paper>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.toolbarBox}>
                    <Typography variant="body1" className={classes.title}>
                        Your Products
                    </Typography>
                </Box>
                <div>
                    {isProducer && (
                      <Button
                        component={RouterLink}
                        to='/bulk-import-new/select-category'
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<CloudUploadOutlinedIcon />}
                        style={{ marginRight: 8 }}
                      >
                          Add/update multiple products
                      </Button>
                    )}
                    <Button
                        component={RouterLink}
                        to={addProductUrl}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        New
                    </Button>
                </div>
            </Toolbar>
            <Divider />
            <ProductCardList
                showDescriptiveSize={true}
                selectedProductId={match?.params?.id}
                productsQuery={ALL_PRODUCTS_PAGINATION_QUERY}
                queryVariables={{ includeDraft: true }}
                showSearch={true}
                showSorting={true}
                showPagination={true}
                // notificationsData={productNotifications}
                showEdit={true}
                showSuperuserNotifications={isStore}
                storeMe={itsMe}
            />
        </Paper>
    );
});

export default UserProductsPage;
